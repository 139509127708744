<template>
<div data-toggle="datepicker"></div>
    <div class="" v-show="playerInfo.isShowData">
         <div class="team-info__overview-card" >
                    <div class="team-info__overview-header">{{$t('GENERAL')}}</div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('NAME')}}</div>
                        <div class="">{{playerInfo.name===null ?'-':playerInfo.name}}</div>
                    </div>
                    <!-- <div class="team-info__overview-body">
                        <div class="">{{$t('FIRST_NAME')}}</div>
                        <div class="">{{playerInfo.firstname===null ?'-':playerInfo.firstname}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('LAST_NAME')}}</div>
                        <div class="">{{playerInfo.lastname===null ?'-':playerInfo.lastname}}</div>
                    </div> -->
                      <div class="team-info__overview-body">
                        <div class="">{{$t('TEAM')}}</div>
                        <div class="">{{playerInfo.team===null ?'-':playerInfo.team}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('NATIONALITY')}}</div>
                        <div class="">{{playerInfo.nationality===null ?'-':playerInfo.nationality}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('BIRTHDATE')}}</div>
                        <div class="">{{playerInfo.birthdate===null ?'-':playerInfo.birthdate}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('AGE')}}</div>
                        <div class="">{{playerInfo.age===null ?'-':playerInfo.age}}</div>
                    </div>
                    <!-- <div class="team-info__overview-body">
                        <div class="">{{$t('BIRTH_PLACE')}}</div>
                        <div class="">{{playerInfo.birthplace===null ?'-':playerInfo.birthplace}}</div>
                    </div> -->
                    <div class="team-info__overview-body">
                        <div class="">{{$t('POSITION')}}</div>
                        <div class="">{{playerInfo.position===null ?'-':playerInfo.position}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('HEIGHT')}}</div>
                        <div class="">{{playerInfo.height===null ?'-':playerInfo.height}}</div>
                    </div>
                    <div class="team-info__overview-body">
                        <div class="">{{$t('WEIGHT')}}</div>
                        <div class="">{{playerInfo.weight===null ?'-':playerInfo.weight}}</div>
                    </div>

        </div>
        <div class="team-info__overview-card" v-if="playerInfo.honours">
            <div class="team-info__overview-header">{{$t('TROPHIES')}}</div>
            <div class="team-info__overview-body" v-for="(o, index) in playerInfo.honours" :key="index">
                <div class="">
                    <span>{{$t(o.country)}}</span>
                    <span class="mlr-02rem">:</span>
                    <span>{{$t(o.league)}}</span>
                    <span class="mlr-02rem">-</span>
                    <span>{{$t(o.status)}}</span>
                </div>
                <div class="">{{o.count}}</div>
            </div>
        </div>
    </div>
    <div class="no-data" v-show="!playerInfo.isShowData">{{$t('NO_OVERVIEW')}}</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import $ from 'jquery'
export default {
    computed: {
        ...mapGetters([
            'playerInfo',
            'isMobile'
        ]),
    },
}
// $('[data-toggle="datepicker"]').datepicker();

</script>


<style>
.team-info__overview-header{
    margin: 0 1rem;
    margin-bottom: 1.45rem;
    font-size: .87rem;
    font-weight: 400;
}
.team-info__overview-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .7rem;
    padding: .8rem 0.5rem;
    border-bottom: .1rem solid #212B30;
    color: var(--color-grey-light);
    margin: 0 .5rem;
}
.team-info__overview-body:last-child{
    border-bottom:none
}
</style>